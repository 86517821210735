<template>
  <div class="container error">
    <transition appear name="slide-fade">
      <Block id="error">
        <div class="code">
          401
        </div>
        <div class="message">
          {{ $t('denied') }}
        </div>
        <div class="actions">
          <Btn class="inv" to="Index">
            {{ $t('back') }}
          </Btn>
        </div>
      </Block>
    </transition>
  </div>
</template>

<script>
import Errors from 'views/mixins/Errors'

export default {
  mixins: [Errors],
}
</script>

<i18n>
{
  "de": {
    "back": "Gehen Sie zur Homepage",
    "denied": "Zugriff abgelehnt"
  },
  "en": {
    "back": "Go to homepage",
    "denied": "Access denied"
  },
  "it": {
    "back": "",
    "denied": ""
  },
  "lt": {
    "back": "Eiti į pagrindinį puslapį",
    "denied": "Prieiga atmesta"
  },
  "pl": {
    "back": "Przejdź do strony głównej",
    "denied": "Odmowa dostępu"
  }
}
</i18n>
